import { CompVariantsViewItem } from '@wix/thunderbolt-catharsis'
import { CssValue, SelectorObj } from '../../shared.types'
import {
	getVariantSelectors,
	variantsComparator,
	getVariantIds,
	VARIANTS_SEPARATOR,
	isRepeaterPattern,
} from '../../utils/variantsUtils'
import { fromClassicCss } from '../../utils/fromClassicCss'
import { DomApplier } from '../cssFeatures.types'
import { isBreakpointRange } from '../../utils/is'
import { BaseDataItem, InnerVariant } from '@wix/thunderbolt-becky-types'

export const FALLBACK_MINIMUM_VIEWPORT = 320
export const FALLBACK_MAXIMUM_VIEWPORT = 1920

export const COMPONENTS_WITH_PORTAL_PREFIXES: Record<string, string> = {
	DatePicker: 'portal-',
	ComboBoxInput: 'listModal_',
	RichTextBox: 'linkModal_',
	VideoBox: 'VideoBox_dummy_',
}

const cleanPatternsVariants = (variantKey: string, themeVariants: CompVariantsViewItem | null) =>
	getVariantIds(variantKey)
		.filter(
			(variantId) => themeVariants?.[variantId] && !isRepeaterPattern(themeVariants?.[variantId] as InnerVariant)
		)
		.join(VARIANTS_SEPARATOR)

export const styleBasedDomApplier: DomApplier<'styleBased'> = (
	{ id: compId, componentType },
	idSelector,
	breakpointId,
	{ themeVariants, compTheme, viewportSize },
	accumulatedData
) => {
	if (!compTheme) {
		return null
	}

	if (compTheme.type === 'classic') {
		return fromClassicCss(compTheme.css as CssValue, idSelector)
	}

	const cssInBreakpoint = compTheme.css?.[breakpointId]
	if (!cssInBreakpoint) {
		return null
	}

	const viewportSizeVars = getViewportSizeVars(
		idSelector,
		viewportSize?.[breakpointId],
		themeVariants?.[breakpointId]
	)

	return Object.entries(cssInBreakpoint)
		.sort(variantsComparator(themeVariants!))
		.reduce<SelectorObj>((acc, [variantKey, cssObj]) => {
			const domSelector = getVariantSelectors(variantKey, themeVariants, compId, accumulatedData).join(',')
			acc[domSelector] = Object.assign(acc[domSelector] || {}, cssObj)

			const prefix = COMPONENTS_WITH_PORTAL_PREFIXES[componentType]
			if (!prefix) {
				return acc
			}

			// TODO: remove this when we move to classes and assign the component class to the portal
			const portalDomSelector = getVariantSelectors(
				cleanPatternsVariants(variantKey, themeVariants),
				themeVariants,
				`${prefix}${compId}`,
				{}
			).join(',')
			acc[portalDomSelector] = Object.assign(acc[portalDomSelector] || {}, cssObj)

			return acc
		}, viewportSizeVars)
}

const getViewportSizeVars = (
	idSelector: string,
	viewportSize: boolean | undefined,
	breakpointRange: BaseDataItem | undefined
): SelectorObj => {
	if (!viewportSize || !isBreakpointRange(breakpointRange)) {
		return {}
	}

	return {
		[idSelector]: {
			'--minViewportSize': breakpointRange.min || FALLBACK_MINIMUM_VIEWPORT,
			'--maxViewportSize': breakpointRange.max || FALLBACK_MAXIMUM_VIEWPORT,
		},
	}
}
